@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom CKEditor styles here */
.ck-editor__editable {
    /* Your custom styles */
    min-height: 400px;
    background-color: white;
    margin-bottom: 2rem;
}